import React from "react";

import { Container } from "react-bootstrap";

import Page from "components/Page/Page";

const config = [
  {
    name: "Aboriginal Artwork",
    moduleComponentName: "AboriginalArtworkBanner",
    rowProps: {},
    moduleProps: { encapsulate: true },
  },
  {
    name: "Upcoming events",
    moduleComponentName: "SectionHeadingWithButtons",
    rowProps: {
      className: "pt-3 pb-5 bg-white text-primary",
      noGutters: true,
    },
    moduleProps: {
      sectionHeadingProps: {
        heading: {
          priority: 2,
          style: {
            fontWeight: "bold",
          },
          text: "Upcoming events",
        },
        showClosedSubmissionAlert: true,
      },
      buttons: [
        {
          text: "Community information events",
          props: {
            variant: "primary",
            href: "#community-information-events",
          },
        },
      ],
    },
    wrapWithContainer: true,
  },
  {
    name: "Visit us",
    id: "Visit us",
    moduleComponentName: "SectionContent",
    columnProps: {
      xs: 12,
      className: "mb-3",
    },
    rowProps: { noGutters: true },
    moduleProps: {
      elementId: "visit-us",
    },
  },
];
const VisitUs = () => {
  return (
    <Container fluid className="page-view">
      <Page config={config} />
    </Container>
  );
};

export default VisitUs;
